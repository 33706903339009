import { RouterModule, Routes } from '@angular/router';
import { TitleResolver } from '@core/services/title/title-resolver.service';
import { AutomaticLogoutComponent } from '@public/automatic-logout/automatic-logout.component';
import { ForgotPasswordDDComponent } from '@public/forgot-password-dd/forgot-password-dd.component';
import { LoginDeutscheDienstradComponent } from '@public/login-dd/login-dd.component';
import { ErrorPageComponent } from '../public/error-page/error-page.component';
import { RedirectComponent } from '../public/external/redirect/redirect.component';
import { ForgotPasswordComponent } from '../public/forgot-password/forgot-password.component';
import { MaintenanceComponent } from '../public/maintenance/maintenance.component';
import { ResetPasswordComponent } from '../public/reset-password/reset-password.component';
import { PublicLayoutComponent } from '@layout/public-layout/public-layout';
import { SimpleDialogBoxComponent } from '@public/simple-dialog-box/simple-dialog-box.component';

const routes: Routes = [
    {
        path: 'error/:errorCode',
        component: ErrorPageComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.ERROR',
        },
    },
    {
        path: 'portal/login',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: 'portal',
        loadChildren: () =>
            import('./portal/portal.module').then((m) => m.PortalModule),
        data: {
            preload: false,
        },
    },
    {
        path: 'lieferanten',
        loadChildren: () =>
            import('./supplier/supplier.module').then((m) => m.SupplierModule),
        data: {
            preload: false,
        },
    },
    {
        path: 'firma/:companySlug/admin',
        loadChildren: () =>
            import('./company/admin/ca.module').then((m) => m.CompanyModule),
        data: {
            preload: false,
        },
    },
    {
        path: 'firma/:companySlug/employee',
        loadChildren: () =>
            import('./company/employee/employee.module').then(
                (m) => m.EmployeeModule,
            ),
        data: {
            preload: false,
        },
    },
    {
        path: 'external',
        loadChildren: () =>
            import('./external/external.module').then((m) => m.ExternalModule),
    },
    {
        path: 'redirect',
        component: RedirectComponent,
    },
    {
        path: 'firma',
        redirectTo: '/error/404?message=Nicht gefunden',
        pathMatch: 'full',
    },

    {
        path: '',
        component: PublicLayoutComponent,
        data: {
            title: 'ROUTE_TITLES.LOGIN',
        },
        children: [
            {
                path: '',
                component: LoginDeutscheDienstradComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.LOGIN',
                },
            },
            {
                path: 'recovery-password',
                component: ForgotPasswordDDComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.RESET_PASSWORD',
                },
            },
            {
                path: 'password',
                children: [
                    {
                        path: 'forgot-password',
                        component: ForgotPasswordComponent,
                        title: TitleResolver,
                        data: {
                            title: 'ROUTE_TITLES.RESET_PASSWORD',
                        },
                    },
                    {
                        path: 'reset',
                        component: ResetPasswordComponent,
                        title: TitleResolver,
                        data: {
                            title: 'ROUTE_TITLES.RESET_PASSWORD',
                        },
                    },
                ],
            },

            {
                path: 'automatic-logout',
                component: AutomaticLogoutComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.LOGOUT',
                },
            },
            {
                path: 'maintenance',
                component: MaintenanceComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.MAINTENANCE',
                },
            },
            {
                path: 'logout',
                component: SimpleDialogBoxComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.LOGOUT',
                },
            },
        ],
    },
    {
        path: 'commercial-emails',
        component: SimpleDialogBoxComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.COMMERCIAL_EMAILS',
        },
    },
    {
        path: '**',
        redirectTo: '/error/404?message=Nicht gefunden',
    },
];

export const PortalRoutes = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    paramsInheritanceStrategy: 'always',
});
